<template>
  <el-card class="box-card full-screen cash-record-statistics">
    <div ref="pageHead" class="table-page-head">
      <el-form :inline="true" :model="queryForm">
        <el-form-item :label="$t('amount')">
          <el-input v-model="queryForm.amount" size="small" />
        </el-form-item>
        <el-form-item :label="$t('status')">
          <el-select v-model="queryForm.status" size="small">
            <el-option value="" :label="$t('all')"></el-option>
            <el-option value="0" :label="$t('unreceived')"></el-option>
            <el-option value="1" :label="$t('received')"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList" :loading="isLoading"><i class="el-icon-search"></i> {{$t('query')}}</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="data-table" :style="{top:queryTableHeight,height:`calc(100% - ${queryTableHeight} - 70px)`}">
      <el-table :data="tableData.records" v-loading="isLoading" element-loading-background="rgba(255,255,255,0.4)" size="mini" height="100%">
        <el-table-column type="index" align="center" fixed="left" width="60"></el-table-column>
        <!-- <el-table-column prop="name" :label="$t('tradeAssistant')" min-width="160">
          <template slot-scope="scope">
            {{scope.row.name}} {{scope.row.surname}}
          </template>
        </el-table-column> -->
        <el-table-column prop="amount" :label="$t('amount')" min-width="160">
          <template slot-scope="scope">
            <span class="franc">₣</span> {{scope.row.amount.formatMoney(0)}}
          </template>
        </el-table-column>
        <el-table-column prop="status" :label="$t('status')" min-width="160">
          <template slot-scope="scope">
            <span v-if="scope.row.status==0" class="text-danger">{{$t('unreceived')}}</span>
            <span v-if="scope.row.status==1" class="text-success">{{$t('received')}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dateTime" :label="$t('time')" min-width="160">
          <template slot-scope="scope">
            {{scope.row.dateTime?$manba(scope.row.dateTime).format('ymdhms'):''}}
          </template>
        </el-table-column>
        <el-table-column fixed="right" :label="$t('extend')" width="100" v-if="loginInfo.orgType!=1">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status==0" @click="updateStatus(scope.row)" size="mini" type="primary">{{$t('receive')}}</el-button>
            <el-button v-else size="mini" type="primary" disabled>{{$t('receive')}}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination background layout="total,sizes,prev,pager,next,jumper" :total="tableData.total||0" :page-sizes="[20, 30, 50, 100, 200]" :page-size="pageSize" :current-page="currentPage" @size-change="setPageSzie" @current-change="setCurrentChange" @prev-click="setCurrentChange" @next-click="setCurrentChange" />
  </el-card>
</template>

<script>
import card from '@/api/card'

export default {
  name: "cashToReceive",
  data () {
    return {
      queryForm: {},
      //table高度，动态设置
      queryTableHeight: String,
      //分页器默认参数
      pageSize: 20,
      currentPage: 1,
      tableData: {},
      //是否加载中
      isLoading: false,
      //列表数据
      table: new Array(),
      addForm: {},

      orgUser: [],
    }
  },
  computed: {
    rules () {
      return {
        tradeAssistant: [{ type: "array", required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        amount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }]
      }
    },
    loginInfo () { return this.$store.state.globalParameter.loginInfo; }
  },
  methods: {
    getList () {
      let _this = this;
      card.pageCashReceive({
        param: { current: _this.currentPage, size: _this.pageSize, type: 0, loginName: _this.loginInfo.loginName, ..._this.queryForm },
        success: res => {
          _this.tableData = res.data || {};
        }
      })
    },
    setPageSzie (val) {
      this.pageSize = val;
      this.getList();
    },
    setCurrentChange (val) {
      this.currentPage = val;
      this.getList();
    },
    updateStatus (row) {
      let _this = this;
      card.getTheCash({
        param: { cashReceiveId: row.id },
        success: res => {
          if (res.code == 200) {
            _this.getList();
            _this.$message.success(_this.$t('operateSuccessfully'))
          }
          else
            _this.$message.warning(_this.$t(res.msg))
        }
      })
    }
  },
  mounted () {
    let queryTableHeight = this.$refs.pageHead.offsetHeight + 15;
    this.queryTableHeight = queryTableHeight + 'px';

    this.getList();
  }
}
</script>

<style lang="less" scoped>
</style>